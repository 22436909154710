@import "variables";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;900&display=swap");

html {
  font-size: 100%;
  overflow-x: hidden;
  max-width: 100%;
  color: $primary-white;
} /*16px*/

* {

}

body {
  background: $dark-blue;
  // background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  // background-image: url('./resources/stardust.png');
  // background-size: 800px;
  background-color: $dark-blue;
  // background-image: linear-gradient(to top, #112F4A 0%, #112F4A 1%, #112a4a 100%);
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  line-height: 1.3;
  color: $primary-white;
  width: 100%;
}

button {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
span {
  margin-bottom: 0.938rem;
}

span {
  font-size: inherit;
}

p {
  font-size: 1.125rem;
  color: $secondary-white;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 1.38rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 4.768rem;
  font-weight: 600;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

.p-large {
  font-size: 1.25rem;
  line-height: 1.7;
  color: $secondary-white;
}
.p-small {
  font-size: 1rem;
  line-height: 1.7;
  color: $secondary-white;
  @media (max-width: $breakpoint-sm) {
    // font-size: 0.938rem
  }
}

.primary-blue {
  color: $primary-blue;
}
.secondary-blue {
  color: $secondary-blue;
}
.terciary-blue {
  color: $terciary-blue;
}
.primary-white {
  color: $primary-white;
}
.secondary-white {
  color: $secondary-white;
}
.terciary-white {
  color: $terciary-white;
}

small {
  font-size: 0.938rem;
  line-height: 1.7;
  color: $secondary-white;
}

.tiny {
  font-size: 0.8rem;
}

.m-0 {
  margin: 0;
}
.m-0 {
  margin: 0;
}

body {
  counter-reset: section;
}

.section-title::before {
  counter-increment: section;
  content: "0" counter(section) ". ";
  color: $primary-blue;
  font-size: 1.563rem;
}

.bold {
  font-weight: 600;
}

.flex-horizontal {
  display: flex;
  justify-content: center;
} 

section {
  width: 100%;
  max-width: 1350px;
  margin: auto;
  margin-bottom: 5rem;
  // min-height: 100vh;
  @media (max-width: $breakpoint-md) {
    padding: 30px;
  }
  padding: 50px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  transition: 0.25s;
  color: inherit;
  &:hover {
    opacity: 0.6;
  }
}

.hidden {
  display: none!important;
}

// * {
//   outline: 1px solid #f00 !important;
// }
