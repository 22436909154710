@import '../variables';

.about-section {
    // max-width: 1200px;
    .about-section-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            justify-content: space-between;
        }
        .about-text {
            @media (max-width: 768px) {
                
                margin-left: 0px;
            }
            // max-width: 600px;
            margin-left: 80px;
            .about-tech {
                // flex-wrap: wrap;
                div {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(147px, 1fr));
                    grid-auto-flow: dense;
                    @media (max-width: 768px) { justify-items: center; }
                    // grid-gap: 1rem;
                }
                svg {
                    margin-right: 0.9rem;
                    vertical-align: middle;
                    transform: scale(1.4);
                    @media (max-width: 768px) {
                        margin-right: 0;
                        flex-direction: column;
                        margin-right: 10px;
                    }
                  }
                p {
                    color: $primary-blue;
                    font-size: 0.9rem;
                    display: block;
                    @media (max-width: 768px) {
                    }
                }
            }
        }

        .about-image {
            @media (max-width: 768px) {margin-top: 30px;}
            width: 100%;
            max-width: 300px;
            border-radius: 14px;
            overflow: hidden;
            height: fit-content;
            img {
                width: 100%;
                height: auto;
                transform: scale(1.05);
            }
        }
    }
}