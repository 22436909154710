@import '../variables';

footer {
    padding: 30px 0;
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 2rem auto;
    margin-bottom: 0;
    .footer-logo {
        margin: 1rem 0;
        max-width: 50px;
        img {
            width: 100%;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            display: inline;
            margin: 1rem;
            font-size: 1.5rem;            
            a {
                color: $primary-blue;
            }
        }
    }
}