@import "../variables";

@include keyframes(floating) {
    0% {
        transform: translateY(-1%);
    }
    50% {
        transform: translateY(1%);
    }
    100% {
        transform: translateY(-1%);
    }
}
@mixin floating {
    @include animate(floating, 4s, infinite, normal, ease-in-out);
}

@for $i from 1 through 10 {
    .projects-section > div:nth-child(#{$i}n) {
        .project-card > .project-card-thumbnail {
            animation-delay: #{$i * 0.75}s;
        }
    }
}

.project-card {
    display: flex;
    max-width: 100%;
    margin: 8rem auto;
    margin-bottom: 0;
    position: relative;
    @media (max-width: $breakpoint-lg) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.project-card-thumbnail {
    @media (min-width: $breakpoint-lg) {
        @include floating;
    }

    // width: 600px;
    max-width: 750px;
    img {
        width: 100%;
        height: auto;
        border-radius: 14px;
    }

    opacity: 0.8;
    transition: 0.5s;
    &:hover {
        opacity: 0.9;
    }
    @media (max-width: $breakpoint-lg) {
        display: block;
    }
    @media (max-width: $breakpoint-sm) {
        width: 100vw;
        border-radius: 0;
    }
}

.project-card-text {
    height: min-content;
    position: absolute;
    // bottom: 110px;
    margin-top: -50px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    background: #3a3753;
    background: -webkit-linear-gradient(top left, #3a3753e6, #11304ae6);
    background: -moz-linear-gradient(top left, #3a3753e6, #11304ae6);
    background: linear-gradient(to bottom right, #363258e6, #0e314ee6);
    box-shadow: 0px 13px 41px 0px rgba(20, 25, 42, 0.75);

    padding: 2rem;
    border-radius: 14px;
    backdrop-filter: blur(30px);
    overflow: hidden;
    @media (max-width: $breakpoint-lg) {
        position: relative;
        // margin: auto;
        margin-top: -160px;
    }

    @media (max-width: $breakpoint-sm) {
        // max-width: 100%;
        // border-radius: 0px;
        margin-top: -65px;
        width: 100%;
        /* margin: 0; */
        padding: 30px;
    }

    &:hover {
        img {
            filter: grayscale(0%);
            opacity: 0.1;
            transform: scale(1.05);
        }
    }

    img {
        display: none;
        // pointer-events: none;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        // opacity: 1;
        opacity: 0.07;
        left: 0;
        top: 0;
        filter: grayscale(100%);
        transition: 0.25s;
        @media (min-width: $breakpoint-lg) {
            display: none;
        }
    }

    h2 {
        font-weight: 500;
        margin: 0;
        margin-top: 1rem;
    }

    .project-tech {
        list-style: none;
        padding-left: 0;
        li {
            padding: 0 15px;
            display: inline;
            &:first-child {
                padding-left: 0px;
            }
        }
    }
}

.alternate {
    @media (min-width: $breakpoint-lg) {
        justify-content: flex-end;
        .project-card-text {
            left: 0;
        }
    }
}
