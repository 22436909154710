$primary-blue: #00CBFF;
$secondary-blue: #00A2CC;
$terciary-blue: #006C88;
$dark-blue: #05213b;
$alternate-blue: #112F4A;
$purple: #393753;

$primary-white: #F2F2F2;
$secondary-white: #D6D6D6;
$terciary-white: #BDBDBD;

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }
    @-moz-keyframes #{$animationName} {
      @content;
    }
    @-o-keyframes #{$animationName} {
      @content;
    }
    @keyframes #{$animationName} {
      @content;
    }
  }
  
  @mixin animate($name, $duration, $iteration, $direction, $timingfunction) {
    -webkit-animation-duration: $duration;
       -moz-animation-duration: $duration;
         -o-animation-duration: $duration;
            animation-duration: $duration;
    -webkit-animation-iteration-count: $iteration;
       -moz-animation-iteration-count: $iteration;
         -o-animation-iteration-count: $iteration;
            animation-iteration-count: $iteration;
    -webkit-animation-name: $name;
       -moz-animation-name: $name;
         -o-animation-name: $name;
            animation-name: $name;
    -webkit-animation-direction: $direction;
       -moz-animation-direction: $direction;
         -o-animation-direction: $direction;
            animation-direction: $direction;
    -webkit-animation-timing-function: $timingfunction;
       -moz-animation-timing-function: $timingfunction;
         -o-animation-timing-function: $timingfunction;
            animation-timing-function: $timingfunction;
  }