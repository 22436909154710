@import "../variables";

.hero-section {
    width: 100%;
    max-width: 1375px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    padding: 30px;
    box-sizing: border-box;
    padding-top: 110px;

    @media (min-width: $breakpoint-md) {
        flex-direction: row;
        padding: 50px;
    }

    h1 {
        @media (max-width: $breakpoint-lg) {
            font-size: 2.8rem;
        }
        margin: 1.5rem 0rem;
        line-height: 1;
    }

    p {
        margin: 0;
        line-height: 1.5;
    }

    .hero-text {
        max-width: 525px;
    }

    .computer {
        max-width: 520px;
        overflow: hidden;

        canvas {
            transform: scale(1.4);
            transition: 0.5s;
            width: 100%;
            height: auto;
            @media (max-width: $breakpoint-md) {
                transform: scale(1.2);
            }
            &:hover {
                // transform: scale(1.42);
            }
        }
    }
}

.col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    justify-content: space-around;
}
